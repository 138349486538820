import React from 'react';

const HeartIcon = () => {
  return (
    <svg width="1em" height="1em" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M20.4078 4.54343C19.9203 4.05422 19.341 3.66605 18.7031 3.40119C18.0653 3.13634 17.3814 3 16.6908 3C16.0001 3 15.3162 3.13634 14.6784 3.40119C14.0405 3.66605 13.4612 4.05422 12.9737 4.54343L12.2254 5.30142L11.4772 4.54343C10.9897 4.05422 10.4104 3.66605 9.7725 3.40119C9.13465 3.13634 8.45078 3 7.76013 3C7.06947 3 6.38561 3.13634 5.74775 3.40119C5.1099 3.66605 4.5306 4.05422 4.04308 4.54343C1.98292 6.6036 1.85659 10.0826 4.45123 12.7258L12.2254 20.5L19.9996 12.7258C22.5943 10.0826 22.468 6.6036 20.4078 4.54343Z" stroke="currentColor" strokeWidth="0.8" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
  );
};

export default HeartIcon;