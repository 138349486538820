import React from 'react';

const Joystick = (props) => {
  return (
    <svg className={props.className} width="1em" height="1em" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M6 11H10" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M8 9V13" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M15 12H15.01" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M18 10H18.01" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M17.32 5H6.68C5.69028 5.00023 4.73579 5.36738 4.00103 6.03046C3.26628 6.69355 2.80345 7.60549 2.702 8.59C2.696 8.642 2.692 8.691 2.685 8.742C2.604 9.416 2 14.456 2 16C2 16.7956 2.31607 17.5587 2.87868 18.1213C3.44129 18.6839 4.20435 19 5 19C6 19 6.5 18.5 7 18L8.414 16.586C8.78899 16.2109 9.29761 16.0001 9.828 16H14.172C14.7024 16.0001 15.211 16.2109 15.586 16.586L17 18C17.5 18.5 18 19 19 19C19.7956 19 20.5587 18.6839 21.1213 18.1213C21.6839 17.5587 22 16.7956 22 16C22 14.455 21.396 9.416 21.315 8.742C21.308 8.692 21.304 8.642 21.298 8.591C21.1968 7.60631 20.7341 6.69413 19.9993 6.03083C19.2645 5.36754 18.3099 5.00026 17.32 5Z" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
  );
};

export default Joystick;