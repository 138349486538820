import React from 'react';

const PLaixIcon = () => {
  return (
    <div>
      <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect width="20" height="20" rx="10" fill="currentColor"/>
        <path d="M13.3294 13.3715C12.5335 13.8702 11.6141 14.1354 10.6747 14.1354L9.10596 14.1354L9.10596 12.0272H10.6747C11.2555 12.0272 11.8233 11.8537 12.3061 11.5285C12.7491 11.2302 13.1025 10.8165 13.3294 10.3328V13.3715Z" fill="url(#paint0_linear_1186_2955)"/>
        <path d="M12.3062 11.5284C12.1401 11.6402 11.964 11.7341 11.7808 11.8092V14.0112C12.3799 13.875 12.9524 13.6287 13.4683 13.2812C14.2952 12.7244 14.9396 11.9329 15.3202 11.0069C15.7008 10.081 15.8004 9.06202 15.6063 8.07899C15.4123 7.09597 14.9334 6.193 14.2302 5.48428C13.527 4.77556 12.6311 4.29291 11.6557 4.09738C10.6804 3.90184 9.66938 4.0022 8.7506 4.38575C7.83183 4.76931 7.04653 5.41884 6.49403 6.25221C5.94153 7.08558 5.64663 8.06536 5.64663 9.06764L7.73835 9.06764C7.73835 8.48231 7.91057 7.91012 8.23323 7.42343C8.55589 6.93674 9.0145 6.55742 9.55107 6.33342C10.0876 6.10942 10.6781 6.05081 11.2477 6.16501C11.8173 6.2792 12.3405 6.56106 12.7512 6.97496C13.1618 7.38885 13.4415 7.91618 13.5548 8.49027C13.6681 9.06436 13.61 9.65941 13.3877 10.2002C13.1655 10.741 12.7891 11.2032 12.3062 11.5284Z" fill="white"/>
        <path d="M4.5 10.3849L7.79846 10.3851V16H5.70675V12.2566H4.5V10.3849Z" fill="white"/>
      <defs>
        <linearGradient id="paint0_linear_1186_2955" x1="11.6852" y1="12.7772" x2="9.2566" y2="12.7792" gradientUnits="userSpaceOnUse">
        <stop stopColor="white"/>
        <stop offset="1" stopColor="white" stopOpacity="0"/>
        </linearGradient>
      </defs>
      </svg>
    </div>
  );
};

export default PLaixIcon;